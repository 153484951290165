<template>
  <div>
    <headerer></headerer>
    <div class="backserve">
      <img src="https://oss.yedengtech.com/website-m/platform/kgstore/bg.png" alt="topbg" class="topbg" />
      <p class="title">更多样化的产品服务</p>
      <div class="backservebox">
        <p class="title2">MCN合作</p>
        <p class="common">
          平台与mcn机构合作，为达人争取更多流量曝光的同时为各位内容创作者提供全面的知识内容变现方案，通过“流量+运营指导”，快速实现变现。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/kgstore/1.png" alt="">

        <p class="title2">讲师直播客户端</p>
        <p class="common">
          平台内直播功能打破传统知识付费录播课形式，为达人解放更多元化的知识付费玩法。多人实时音频/视频连麦与一键管理学生功能，1:1还原真实课堂，真正做到把线下课堂搬到线上。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/kgstore/2.png" alt="">


        <p class="title2">易知驾考助手app</p>
        <p class="common">
          成熟独立的驾考系统、完善的驾考题目，让用户不仅能跟随讲师学习还能享受在小程序内进行驾考理论知识测试的服务，同时也能作为达人讲师的驾考课程卖点。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/kgstore/3.png" alt="">

      </div>
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
export default {
  components: {
    headerer,
    footerer,
  },
};
</script>

<style lang="less" scoped>
.backserve {
  width: 100vw;
  display: flex;
  flex-direction: column;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;
  .backservebox {
    padding: 10px;
    img{
      width: 100%;
      object-fit: contain;
    }
  }
}

.topbg {
  width: 100%;
  object-fit: contain;
}

.title {
  font-weight: 600;
  margin: 20px 0;
  font-size: 18px;
  color: #000;
  text-align: center;
}
.title2 {
  font-weight: 600;
  font-size: 15px;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title2::after {
  content: "";
  display: block;
  width: 45px;
  height: 3px;
  margin-top: 10px;
  background: rgb(25 94 215);
  border-radius: 10px;
}

.common {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
