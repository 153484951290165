<template>
  <div class="wellperson">
    <p class="title">头部达人，都在选择我们</p>
    <p class="titleafter">目前已经有6万+的达人选择我们</p>

    <div class="swipercontent">
      <van-swipe
        :show-indicators="false"
        class="myswipe"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item class="myswiperitem">
          <div class="theteacher">
            <img src="" alt="teacher" />
            <div class="teacherinfo">老师简介</div>
          </div>
        </van-swipe-item>

        <van-swipe-item class="myswiperitem">
          <div class="theteacher">
            <img src="" alt="teacher" />
            <div class="teacherinfo">老师简介</div>
          </div>
        </van-swipe-item>

        <van-swipe-item class="myswiperitem">
          <div class="theteacher">
            <img src="" alt="teacher" />
            <div class="teacherinfo">老师简介</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="btn">更多案例</div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
};
</script>

<style lang="less" scoped>
.wellperson {
  padding: 20px 0;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;

  .persons {
    width: 100%;
    height: calc(100vh * 0.172);
    margin-top: 20px;
  }
}

.title {
  width: 100%;
  background: linear-gradient(
      90deg,
      #ffef61 15%,
      #ff93e1 58.54%,
      #aa95ff 66.67%,
      #95dfff 90%
    ),
    #fff;
  font-size: 16px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.titleafter {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  text-align: center;
  margin-top: 8px;
}

.btn {
  width: 29%;
  height: calc(100vh * 0.04);
  font-size: 12px;
  color: #000;
  background-color: rgb(240 244 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-top: 20px;
}

.swipercontent {
  width: 80%;
  height: 160px;
  border: 1px solid #293241;
  background: rgba(28, 35, 46, 0.86);
  box-shadow: inset 0 -1px 1px rgba(172, 225, 255, 0.05),
    inset 0 1px 1px rgba(172, 225, 255, 0.06);
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
  .theteacher {
    padding: 10px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    .teacherinfo {
      width: 200px;
    }
  }
}
</style>
