const ourpowerdata = [
  [
    {
      num: "01",
      titlep: "双软企业",
      powerinfo: "获得国家软件企业和软件产品认证",
    },
    {
      num: "02",
      titlep: "双高企业",
      powerinfo: "获得国家高新技术企业和高新技术产品认证",
    },
    {
      num: "03",
      titlep: "技术著作权",
      powerinfo: "获十余项产品自主知识产权软件著作权证书",
    },
    {
      num: "04",
      titlep: "多端优质服务商",
      powerinfo: "累计用户3亿、5万+知识商品、5万+入驻达人",
    },
  ],

  [
    {
      num: "01",
      titlep: "自研能力",
      powerinfo: "行业前沿开发技术实力和资深工程研发团队",
    },
    {
      num: "02",
      titlep: "智能直播",
      powerinfo: "支持多终端获客，多渠道引流，助力打造私域流量",
    },
    {
      num: "03",
      titlep: "多终端访问入口",
      powerinfo: "获得国家软件企业和软件产品认证",
    },
    {
      num: "04",
      titlep: "大数据分析",
      powerinfo: "后台数据实时监控，多维度多指标深度分析",
    },
  ],

  [
    {
      num: "01",
      titlep: "孵化期定位",
      powerinfo: "根据实际情况，制定详细的商业策略，缩短孵化期",
    },
    {
      num: "02",
      titlep: "上升期扶持",
      powerinfo: "通过后台数据支持，在上升期给予客户更多战略建议",
    },
    {
      num: "03",
      titlep: "爆发期增长",
      powerinfo: "助力内容创作者获得更多曝光，快速增长内容变现收益",
    },
    {
      num: "04",
      titlep: "成熟期监控",
      powerinfo: "定期回访、实时解答运营中出现的问题，维稳数据增长",
    },
  ],
];


export {ourpowerdata}