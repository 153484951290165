<template>
  <div>
    <headerer></headerer>
    <div class="backserve">
      <img
        src="https://oss.yedengtech.com/website-m/platform/bkserve/bg.png"
        alt="topbg"
        class="topbg"
      />
      <p class="title">更全面的管理模块</p>
      <div class="backservebox">
        <p class="title2" id="part1">付费用户统计</p>
        <p class="common">
          对付费用户的分析包含：付费行为分析、终端分析、用户画像等；目标人群定位更清晰评论系统。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/bkserve/1.png" alt="" />

        <p class="title2" id="part2">评论系统</p>
        <p class="common">
          讲师端评论系统全面支持讲师售课策略，精准控制每一条评论内容，轻松实现课程0差评，同时更有一键导入多条、评论时间随机等功能，还原真实评论。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/bkserve/2.png" alt="" />

        <p class="title2" id="part3">分销市场</p>
        <p class="common">
          易知课堂已合作了涵盖教育、⼼理、健康、娱乐等多领域的优质内容，提供⾼分成分销课程。对于没有条件制作知识课程的达⼈，可以通过分销市场⼀键上架⾄⾃⼰的易知课堂店铺，并分销售卖。快速进⾏知识变现，解决内容问题。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/bkserve/3.png" alt="" />

        <p class="title2" id="part4">完整的订单管理</p>
        <p class="common">
          实时售卖指标监测，清晰查看在售商品付费排⾏以及单个商品访问和付费情况，⽀持课程及推⼴号多维度分析运营效果，及时制定下⼀步计划、调整营销策略及场景，实现转化率和⽤户复购率提升。后台增⻓数据可视化，实现数字化运营管理。
        </p>
        <img src="https://oss.yedengtech.com/website-m/platform/bkserve/4.png" alt="" />
      </div>
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
export default {
  components: {
    headerer,
    footerer,
  },
};
</script>

<style lang="less" scoped>
.backserve {
  width: 100vw;
  display: flex;
  flex-direction: column;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;
  .backservebox {
    padding: 10px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.topbg {
  width: 100%;
  object-fit: contain;
}

.title {
  font-weight: 600;
  margin: 20px 0;
  font-size: 18px;
  color: #000;
  text-align: center;
}
.title2 {
  font-weight: 600;
  font-size: 15px;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title2::after {
  content: "";
  display: block;
  width: 45px;
  height: 3px;
  margin-top: 10px;
  background: rgb(25 94 215);
  border-radius: 10px;
}

.common {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
