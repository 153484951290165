import { render, staticRenderFns } from "./ourpower.vue?vue&type=template&id=59da82d1&scoped=true&"
import script from "./ourpower.vue?vue&type=script&lang=js&"
export * from "./ourpower.vue?vue&type=script&lang=js&"
import style0 from "./ourpower.vue?vue&type=style&index=0&id=59da82d1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21__w64yi6semcershf7tc4sxixniq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59da82d1",
  null
  
)

export default component.exports