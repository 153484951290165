<template>
  <div class="home">
    <headerer></headerer>
    <topbg></topbg>
    <productshow></productshow>
    <serveshow></serveshow>
    <ourpower></ourpower>
    <!-- <wellperson></wellperson> -->
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "./components/headerer.vue";
import topbg from "./components/home/topbg.vue";
import productshow from "./components/home/productshow.vue";
import serveshow from "./components/home/serveshow.vue";
import ourpower from "./components/home/ourpower.vue";
import wellperson from "./components/home/wellperson.vue";
import footerer from "./components/footerer.vue";
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    topbg,
    headerer,
    footerer,
    productshow,
    serveshow,
    ourpower,
  },
  activated() {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  min-height: 100vh;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;
}
@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
